import httpRequest from '@/utils/network/request';

// 发送推送消息
export const pushAppMsg = function (params) {
  const url = '/push/api/push/topic';
  return httpRequest.post(url, params);
};

// 发送推送消息
export const getPushMsgHistory = function (params) {
  const url = '/push/api/push/records';
  return httpRequest.get(url, params);
};