<template>
  <div>
    <transition name="el-zoom-in-center">
      <el-form v-show="showForm" class="form" ref="form" label-width="180px">
        <el-form-item label="应用：">
          <el-form-item label="微爱" label-width="80px">
          </el-form-item>
        </el-form-item>
        <el-form-item label="环境：">
          <el-select v-model="currentEnv" @change="selectEnv" placeholder="请选择环境">
              <el-option
              v-for="item in envOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备：">
          <el-checkbox-group v-model="platformList">
            <el-checkbox label="安卓"></el-checkbox>
            <el-checkbox label="iOS"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="app版本：">
          <el-input v-model="version" placeholder="多个版本用逗号隔开" style="width: 400px"></el-input>
        </el-form-item>
        <el-form-item label="标题：">
          <el-input v-model="title" placeholder="请输入标题" style="width: 400px"></el-input>
        </el-form-item>
        <el-form-item label="内容：">
          <el-input v-model="content" placeholder="请输入内容" style="width: 400px"></el-input>
        </el-form-item>
        <el-form-item label="网页url：">
          <el-input v-model="web_url" placeholder="请输入网页url" style="width: 400px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="confirmClick">确认发送</el-button>
        </el-form-item>

      </el-form>
    </transition>
  </div>
</template>

<script>
import {pushAppMsg} from '@/api/pushMsg.js';

export default {
  data () {
    return {
      showForm: false,
      envOptions: [{
        value: 'env_prod',
        label: '正式环境'
      }, {
        value: 'env_unstable',
        label: '测试环境'
      }],
      currentEnv: 'env_unstable',
      platformList: [],
      version: '',
      title: '',
      content: '',
      web_url: ''
    };
  },
  methods: {
    selectEnv (e) {

    },

    confirmClick () {
      console.log('currentEnv:', this.currentEnv);
      console.log('platformList:', this.platformList[0]);
      console.log('title:', this.title);
      console.log('content:', this.content);
      if (this.platformList.length == 0) {
        this.$message.error('没有勾选设备');
        return;
      }
      if (!this.title) {
        this.$message.error('没有填写标题');
        return;
      }
      if (!this.content) {
        this.$message.error('没有填写内容');
        return;
      }
      if (this.web_url.length > 0 && this.web_url.indexOf('http') < 0) {
        this.$message.error('网页url必须为http链接');
        return;
      }
      pushAppMsg({
        app_type: 'welove',
        env: this.currentEnv,
        android: this.platformList.indexOf('安卓') > -1 ? '1' : '0',
        ios: this.platformList.indexOf('iOS') > -1 ? '1' : '0',
        appversions: this.version,
        title: this.title,
        content: this.content,
        web_url: this.web_url
      })
        .then((res) => {
          console.log('发推送成功：', res);
          this.$message({
            message: '推送消息发送成功',
            type: 'success'
          });
        })
        .catch((error) => {
          console.log('发推送失败：', error);
          this.$message.error(error.msg);
        });
    }
  },

  beforeCreate () {},
  created () {
  },
  beforeMount () {},
  mounted () {
    this.showForm = true;
  },
  beforeDestroy () {
  },
  destroyed () {}
};
</script>
<style lang="scss">
.form{
  padding: 20px;
  margin-top: 60px;
  margin-left: 20%;
  background-color: white;
  width: 60%;
  border-radius: 20px;
}
</style>
